import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import classNames from "classnames"
import CountUp from "react-countup"
import "isomorphic-fetch"
import Pipe from "../components/pipe"
import Results from "../components/results"

const VOTE = {
  OPEN: "open",
  NOT_OPEN: "not_open",
  CLOSED: "closed",
  FINAL: "final",
}

const activeEnv =
  process.env.ACTIVE_ENV || process.env.NODE_ENV || "development"

// const results = { "voting_status": "not_open", "votes": [{ "name": "Asian Women on the Telephone", "country": "Russia", "count": 0 }, { "name": "E-P-P", "country": "Serbia", "count": 0 }, { "name": "Felix Kubin", "country": "Germany", "count": 1 }, { "name": "Golden Core", "country": "Norway", "count": 0 }, { "name": "Hassan K", "country": "France", "count": 0 }, { "name": "Johnny the Horse", "country": "Czech Republic", "count": 121 }, { "name": "Maraudeur", "country": "Switzerland", "count": 0 }, { "name": "Sissy", "country": "Ireland", "count": 0 }, { "name": "tab_ularasa", "country": "Italy", "count": 0 }, { "name": "The Callas", "country": "Greece", "count": 145 }, { "name": "Winny Puhh", "country": "Estonia", "count": 0 }] }

class ResultsPage extends React.Component {
  render() {
    return (
      <Layout
        color="white"
        background="transparent"
        classNames="page page--results"
        empty
      >
        <SEO
          title="Results"
          pathname={this.props.location.pathname}
          description=""
        />
        <Results status={false} api="https://app.euronoize.eu/api/results" />
      </Layout>
    )
  }
}

export default ResultsPage
