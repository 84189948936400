import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import classNames from "classnames"
import CountUp from "react-countup"
import "isomorphic-fetch"
import Pipe from "../components/pipe"

const VOTE = {
  OPEN: "open",
  NOT_OPEN: "not_open",
  CLOSED: "closed",
  FINAL: "final",
}

const activeEnv =
  process.env.ACTIVE_ENV || process.env.NODE_ENV || "development"

const results = {
  voting_status: "not_open",
  votes: [
    { name: "Asian Women on the Telephone", country: "Russia", count: 9 },
    { name: "E-P-P", country: "Serbia", count: 23 },
    { name: "Felix Kubin", country: "Germany", count: 18 },
    { name: "Golden Core", country: "Norway", count: 33 },
    { name: "Hassan K", country: "France", count: 24 },
    { name: "Johnny the Horse", country: "Czech Republic", count: 5 },
    { name: "Maraudeur", country: "Switzerland", count: 7 },
    { name: "Sissy", country: "Ireland", count: 33 },
    { name: "tab_ularasa", country: "Italy", count: 5 },
    { name: "The Callas", country: "Greece", count: 7 },
    { name: "Winny Puhh", country: "Estonia", count: 22 },
  ],
}

class Results extends React.Component {
  state = {
    status: VOTE.FINAL,
    votes: results.votes,
    previous: results.votes,
  }

  fetchResults = (previous = false) => {
    // prod :
    // const api = activeEnv === 'development' ? 'https://app.euronoize.eu/api/results-test' : 'https://app.euronoize.eu/api/results'
    const api = this.props.api
      ? this.props.api
      : "https://app.euronoize.eu/api/results"

    console.log(`Fetching results, using api ${api} on env '${activeEnv}'`)

    fetch(api)
      .then(response => {
        return response.json()
      })
      .then(data => {
        this.setState({
          status:
            activeEnv === "development"
              ? this.state.status
              : data.voting_status,
          votes: data.votes,
          previous: previous ? previous : data.votes,
        })
      })
      .catch(function(err) {
        console.log("Error", err)
      })
  }

  componentDidMount() {
    // this.fetchResults()
    // this.interval = setInterval(() => this.fetchResults(this.state.votes), 5000)
  }

  componentWillUnmount() {
    // clearInterval(this.interval)
    // this.interval = null
  }

  sortVotes = votes => {
    return votes.sort((a, b) => {
      if (a.country < b.country) {
        return -1
      }
      if (a.country > b.country) {
        return 1
      }
      return 0
    })
  }

  getMaxY(data) {
    if (data.length === 0) {
      return -1
    }

    return data.reduce(
      (max, p) => (p.count > max ? p.count : max),
      data[0].count
    )
  }

  render() {
    const { votes } = this.state

    const status = this.props.status ? this.props.status : this.state.status

    const sortedVotes = this.sortVotes(votes)
    const winningCount = this.getMaxY(votes)

    // const forcedFinal = this.props.status === VOTE.FINAL

    return (
      <div className="results">
        {sortedVotes &&
          sortedVotes.map(contestant => {
            const previous = this.state.previous.find(
              p => contestant.name === p.name
            )
            const animated = previous.count !== contestant.count
            // const animated = ((status === VOTE.NOT_OPEN || status === VOTE.OPEN) && previous.count !== contestant.count)
            const winner =
              status === VOTE.FINAL &&
              winningCount > 0 &&
              contestant.count === winningCount

            if (previous.count !== contestant.count) {
              console.log(
                `${contestant.country}/${
                  contestant.name
                } got ${contestant.count - previous.count} new votes.`
              )
            }

            return (
              <div
                key={contestant.name}
                className={classNames("results__row", {
                  winner,
                })}
              >
                <div
                  className={classNames("results__country", {
                    "animated flash faster": animated,
                  })}
                >
                  {contestant.country}
                </div>
                <div
                  className={classNames("results__name", {
                    "animated flash faster": animated,
                  })}
                >
                  {contestant.name}
                </div>
                <div
                  className={classNames("results__count", {
                    "animated flash faster": animated,
                  })}
                >
                  {winner && this.state.winnerBadge && (
                    <span className="animated fadeIn">
                      <div className="winner-badge animated flash infinite" />
                    </span>
                  )}
                  {status === VOTE.FINAL ? (
                    <CountUp
                      end={contestant.count}
                      onStart={() => this.setState({ winnerBadge: false })}
                      onEnd={() => this.setState({ winnerBadge: true })}
                    />
                  ) : (
                    "–"
                  )}
                </div>
              </div>
            )
          })}
        <div
          className={classNames(
            "results__row uppercase flex justify-center items-center  text-center"
          )}
        >
          <div className="w-full p-4 h-full bg-black text-yellow flex justify-center items-center">
            {/* <span>
              To vote, text{" "}
              <span className="font-sans text-bold text-green">Register</span>
              <br />
              to{" "}
              <span className="font-sans-ex text-bold text-green">
                +44 7723 452211
              </span>
              .
            </span> */}
          </div>
        </div>
      </div>
    )
  }
}

export default Results
